.pagination_results{
    margin-bottom: 20px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 600;
    color: $color-dark-blue2;
}


.pagination {

    margin-top: 20px;
    margin-bottom: 20px;

    .prev, .next{
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background: $color-dark-blue2;
            color: $color-white;
        }

    }



    nav {

        ul {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            

            li {
                list-style: none;
                margin: 0 5px;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    height: 30px;
                    border-radius: 0;
                    background: $color-white;
                    color: $color-dark-blue2;
                    font-size: 14px;
                    font-weight: 600;
                    transition: all .3s ease;

                    &:hover {
                        background: $color-dark-blue2;
                        color: $color-white;
                    }
                }

                a.page--current {
                    background: $color-dark-blue2;
                    color: $color-white;
                }
            }
        }

    }
}