* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    height: 100%;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-weight: $regular;
    overflow-x: hidden;
    // position: relative;

    .swal2-container{
        z-index: 100000000;
    }
}

.p-line-height {
    line-height: 1.5;
}

img {
    max-width: 100%;
    height: auto;
}

section {
    // height: 100vh;
    padding-top: 3rem;

    &.section--with-header {
        padding-top: 0rem;
    }
}
b {
    font-weight: bold;
}
.height-100-view {
    height: 100vh;
}

.height-100-porcentaje {
    height: 100%;
}

.d-none {
    display: none !important;
}

.overhidden {
    overflow: hidden;
}

.link {
    text-decoration: none;
    color: $color-dark-blue;
}

.box-shadow-primary {
    box-shadow: 0 0 1.5rem 0.5rem rgba(0, 0, 0, 0.1);
}

.text-center {
    text-align: center;
}

.border-2 {
    border-top: 2px solid $color-white;
    border-bottom: 2px solid $color-white;
}

.mb-1 {
    margin-bottom: 1rem;
}
.mb-15 {
    margin-bottom: 1.5rem;
}
.mb-2 {
    margin-bottom: 2rem;
}

.pt {
    &-1 {
        padding-top: 1rem;
    }

    &-2 {
        padding-top: 2rem;
    }
}


.user {
    #container {
        height: 100%;
        #main {
            // height: 80%;
            // overflow: hidden; /* todo  */
        }
    }
}

body {
    &.app-auth{
        width: 100%;
        overflow-x: scroll;
    }

    &.app-booking {
        width: 100%;
        overflow-x: scroll;
    }

    &.app-campaign {
        width: 100%;
        overflow-x: scroll;
    }

    &.app-customer {
        width: 100%;
        overflow-x: scroll;
    }

    &.app-django_cron {
        width: 100%;
        overflow-x: scroll;
    }

    &.app-notification {
        width: 100%;
        overflow-x: scroll;
    }

    &.app-user {
        width: 100%;
        overflow-x: scroll;
    }

    
}

.spinner_container {
    display: flex;
    justify-content: center;
    padding-top: 20%;
    position: absolute; 
    top: 0px; 
    left: 0px; 
    width: 100%; 
    height: 100%; 
    z-index: 2000; 
    background-color: rgba(255, 255, 255, 0.836);
}