.user {

    #container{

        #header {

            padding: 0;
            background-color: $color-white;
            display: flex;
            flex-direction: column;

            @include media-query(tablet) {
                flex-direction: row;
                
            }

            #branding{
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: $color-dark-blue;
                padding-top: 0px;
                padding-bottom: 0px;
                padding-left: 20px;
                padding-right: 20px;
                width: 100%;

                @include media-query(tablet) {
                    justify-content: center;
                    width: 250px !important;
                    min-width: 250px !important;
                }

                a {
                    margin: 10px;
                }

                .toggler_menu_container{
                    svg {
                        cursor: pointer;
                        width: 35px;
                        height: 35px;
                        @include media-query(tablet) {
                            display: none;
                        }

                        &:hover{
                            fill: $color-bright-blue;
                        }
                    }

                    .img_profile{
                        width: 35px;
                        height: 35px;
                        border-radius: 6px;
                        margin-left: 10px;
                        cursor: pointer;

                        @include media-query(tablet) {
                            display: none;
                        }
                    }
                }
                
            }

            #user-tools {

                padding: 0;
                display: flex;
                justify-content: space-between;
                color: $color-dark-blue;
                margin: 0;
                position: relative;
                width: 100%;
                padding-left: 0px;
                padding-right: 20px;

                @include media-query(tablet) {
                    height: 100%;
                    padding: 0;
                    padding: 0 30px;
                    display: flex !important;
                    justify-content: space-between !important;
                    width: 100%;
                }

                

                .ubication_container{
                    display: flex;
                    align-items: center;

                    img {
                        width: 70px;
                        height: 70px;
                        margin-left: 20px;
                    }

                    p {
                        margin: 0;
                        margin-left: 10px;
                        padding: 0;
                        font-size: 16px;
                        font-weight: $bold;
                        color: $color-dark-blue;
                    }
                }

                .ubication_text{
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    p {
                        margin: 0px;
                        font-size: 16px;
                        font-weight: 600;
                        text-align: center;
                    }

                    @include media-query(tablet) {
                        display: none;
                    }
                }

                .user-tools_container{
                    margin-right: 10px;
                    display: flex;
                    gap: 10px;
                    right: 0;
                    color: $color-dark-blue;
                    justify-content: center;
                    align-items: center;

                    .link_icon{
                        display: block;
                        color: $color-dark-blue;

                        .icon_container{

                            border-radius: 50%;
                            border: 1px solid $color-dark-blue;
                            width: 35px;
                            height: 35px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
    
                            &:hover{
                                background-color: $color-dark-blue;
                                color: $color-white;
    
                                svg {
                                    color: $color-white;
                                }
                            }
    
                            svg {
                                width: 23px;
                                height: 23px;
                                cursor: pointer;
                            }
    
    
                        }

                    }




                    
    
                    // img {
                    //     fill: #000EAA;
                    //     cursor: pointer;
                    // }
                }
            }
        }
    }
}

