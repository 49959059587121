.user {
    .notifications_container {
        display: flex;
        flex-direction: column;
        max-height: 90vh;
        overflow: scroll;

                // Estilos para el scroll en navegadores webkit (Chrome y Safari)
                &::-webkit-scrollbar {
                    width: 4px; // Grosor del scrollbar vertical en Chrome y Safari
                }
        
                &::-webkit-scrollbar-thumb {
                    background: $color-dark-blue2; // Cambiar el color del scrollbar en Chrome y Safari
                }
        
                &::-webkit-scrollbar-track {
                    background: #f1f1f1; // Cambiar el color del fondo del scrollbar en Chrome y Safari
                }
        
                // Estilos para el scroll en Firefox
                scrollbar-width: thin; // Grosor del scrollbar vertical en Firefox
                scrollbar-color: $color-dark-blue2 #f1f1f1; // Color del scrollbar en Firefox
        
        

        .notifications_title_container {
            background-color: $color-dark-blue2;
            padding: 10px 40px;

            p {
                font-size: 20px;
                font-weight: $semibold;
                color: $color-white;
                margin: 0px;
            }   
        }

        .notifications_subtitle_container {
            background-color: $color-white;
            padding: 20px 40px;
            border-bottom: 1px solid $color-light-gray2;

            p {
                font-size: 16px;
                font-weight: $regular;
                color: $color-dark-gray;
                margin: 0px;
            }
        }

        .notifications_grid {
            background-color: $color-white;

            .notification_item {
                padding: 10px 40px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                border-bottom: 1px solid $color-light-gray2;

                .notification_left {
                    width: 80%;
                    overflow: hidden;

                    .notification_text,
                    .notification_hour {
                        margin: 0px;
                        font-size: 14px;
                    }

                    .notification_hour {
                        color: $color-dark-blue2;
                        font-size: 12px;
                        font-weight: $semibold;
                    }
                }

                .notification_rigth {
                    width: 20%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;

                    .svg_container{
                        border-radius: 50%;
                        border: 1px solid $color-dark-blue2;
                        width: 30px;
                        height: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $color-dark-blue2;
                        cursor: pointer;

                        svg {
                            width: 18px;
                            height: 18px;
                        }

                        &:hover {
                            background-color: $color-dark-blue2;
                            color: $color-white;
                        }
                    }
                }
            }
        }
    }
}
