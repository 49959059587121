@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

@import './base/variables';
@import './base/buttons';
@import './base/mixins';
@import './base/tables';
@import './base/forms';
@import './base/filter';
@import './base/base';
@import './base/texts';
@import './base/extends';
@import './base/grid';
@import './base/spinner';
@import './components/component_dots';
@import './components/admin_items';
@import './components/404';
@import './components/login';
@import './components/user_change_password';
@import './components/admin_header';
@import './components/user_content';
@import './components/aforo';
@import './components/dashboard';
@import './components/booking_list';
@import './components/booking_detail';
@import './components/aforo_detail';
@import './components/admin-nav';
@import './components/paginator';
@import './components/notifications';
@import './components/admin-content';
@import './components/admin-toogle-nav';
@import './components/admin-applist';
@import './components/user_header';
@import './components/user_breadcrumbs';
@import './components/user_password_reset';
@import './components/user_menu';
@import './components/user_profile';
@import './components/detail_crud';
@import './components/usuario_ddoh';
@import './components/container_booking_verify';
@import './components/404';
@import './components/messages';