
// Common styles for the admin nav
%common-styles-header {
    #header {
        padding: 0;
        background-color: $color-white;
        display: flex;
        justify-content: space-between;

    
        #branding {
            background-color: $color-dark-blue;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            @include media-query(tablet) {
                width: 250px;
            }

            a {
                margin: 10px;
            }
            
        }

        #user-tools {

            color: $color-dark-blue;
            margin: 0;
            height: 100%;
            display: none;

            @include media-query(tablet) {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .user-tools_container{
                margin-right: 15px;
                display: flex;
                gap: 10px;

                img {
                    fill: #000EAA;
                    
                    cursor: pointer;
                }
            }

            a {
                color: $color-dark-blue;
            }

            form {
                button {
                    color: $color-dark-blue;
                }
            }
        }

        .ubication {
            top: 17px;
            position: absolute;
            left: 287px;
            color: black;
            font-size: 22px;
            font-weight: 700;
            display: none;
            gap: 10px;

            @include media-query(tablet) {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            p {
                margin: 0;
            }

        }
        
        #logout-form{
            background-color: $color-dark-blue;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            button {
                border: none;
            }

            @include media-query(tablet) {
                display: none;
            }
        }
    }
}


.change-form {
    @extend %common-styles-header; // Extend the common styles to the admin-header
}

.change-list {
    @extend %common-styles-header; 
}

.password {
    @extend %common-styles-header;
}

.dashboard {
    #header {
        justify-content: space-between;

        .ubication{
            display: none;
        }
        #user-tools{
            display: none;
        }
    }
}

.login{
    #logout-form{
        display: none;
    }
}

