






/* 

.aforo{
    .detalle_reserva_container{
        display: flex;
        flex-direction: column;
        gap: 20px;

        

        @include media-query(desktop) {
            flex-direction: row;
        }

        .anounciant_container{
            min-width: 300px;
            .title_container{
                background-color: $color-white;
                border-bottom: 1px solid $color-light-gray2;

                p{
                    font-size: 19px;
                    font-weight: $bold;
                    color: $color-dark-blue;
                    padding-top: 21px;
                    padding-left: 21px;
                    padding-bottom: 21px;
                    margin-bottom: 0px;
                }
            }

            .logo_container{
                display: flex;
                
                background-color: $color-white;
                padding-left: 21px;
                padding-top: 21px;
                padding-bottom: 21px;

                .image_container{

                    width: 80px;
                    height: 80px;
                    border-radius: 10px;
                    overflow: hidden;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .brand_name_container{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-left: 15px;

                    p {
                        margin: 0px;
                    }

                    // Estilo para el primer párrafo
                    p:nth-of-type(1) {
                        font-size: 19px;
                        font-weight: $bold;
                        color: $color-dark-gray;
                        margin-bottom: 0px;
                    }
                    
                    // Estilo para el segundo párrafo
                    p:nth-of-type(2) {
                        font-size: 15px;
                        font-weight: $bold;
                        color: $color-dark-gray;
                        margin-bottom: 0px;
                    }

                    // Estilo para el tercer párrafo
                    p:nth-of-type(3) {
                        font-size: 11px;
                        font-weight: $semibold;
                        color: $color-dark-gray;
                        margin-bottom: 0px;
                    }
                    
                }
            }
        }

        .booking_container{
            .booking_confirm_container{
                background-color: $color-white;
                
            }
        }
    }
}
*/