.user {
    #content-start {
        background-color: $color-light-gray;
        // el 100% - 305px es el ancho del sidebar
        width: calc(100% - 305px);
        min-height: 1000px;

        .breadcrumbs{
            background-color: $color-light-gray;
            padding-top: 30px;
            color: $color-dark-blue2;
            font-size: 16px;

            a {
                color: $color-dark-blue2;
                font-size: 16px;

                &:hover{
                    color: $color-bright-blue;
                    font-weight: $semibold;
                }
            }
        }
    }
}