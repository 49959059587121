.detail{

    p{
        word-break: break-all;
    }

    .filter_sup{
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        @include media-query(desktop) {
            position: absolute;
            top: -41px;
            right: 40px;
        }

        .recordatorios, .gestionar_camp{
            width: 48%;
            min-width: 188px;
            height: 45px;
            background-color: $color-white;
            display: flex;
            color: $color-dark-blue2;
            font-weight: $semibold;
            justify-content: center;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            @include media-query(desktop) {
                width: 200px;
            }

            p{
                margin: 0px;

                // mediaquerie hasta 768px
                @media (max-width: 450px) {
                    font-size: 10px;
                }
            }
        } 

        .recordatorios{
            @include media-query(desktop) {
                margin-right: 10px;
            }
        }
    }



    .detail_main_container {
        display: flex;
        flex-wrap: wrap;
    
        // Primer hijo
        :first-child {
            order: 1;
        }
    
        // Segundo hijo
        :nth-child(2) {
            order: 3;
        }
    
        // Tercer hijo
        :nth-child(3) {
            order: 2;
        }
    
        // Cuarto hijo
        :nth-child(4) {
            order: 4;
        }
    
    
        @include media-query(desktop) {
            // Segundo hijo
            :nth-child(2) {
                order: 2;
            }
    
            // Tercer hijo
            :nth-child(3) {
                order: 3;
            }
        }
    
    
        .image_container {
            width: 100%;
    
            @include media-query(desktop) {
                width: 40%;
                height: 300px;
            }
    
            @include media-query(desktopLS) {
                height: 400px;
            }
    
            img {
                width: 100%;
                height: 100%;
                object-fit: cover; /* Esto ajustará la imagen al contenedor sin deformarla */
            }
    
            margin-bottom: 20px;

            
        }
    
        .reservation_queue_container {
            width: 100%;
            height: 300px;
            overflow-y: scroll;
            margin-top: 20px;
            //background-color: $color-white;
    
            @include media-query(desktop) {
                width: calc(
                    100% - 40%
                ); /* Resta el 40% de ancho de otro elemento (por ejemplo, tu imagen) */
                padding-left: 15px;
    
                margin-top: 0px;
            }
    
            @include media-query(desktopLS) {
                height: 400px;
            }
    
            .title_container {
                background-color: $color-white;
                padding: 15px;
                padding-top: 25px;
                border-bottom: 1px solid $color-light-gray2;
    
                h2 {
                    margin: 0px;
                    color: $color-dark-blue;
                    font-weight: $bold;
                    font-size: 18px;
                }
            }
    
            .queue_body {
                background-color: $color-white;
                padding-top: 20px;

                .void_text{
                    text-align: center;
                    color: $color-gray;
                    font-size: 14px;
                    font-weight: $semibold;
                    padding-bottom: 16px;
                }
    
                .queue_container {
                    display: flex;
                    flex-wrap: wrap;
                    border-bottom: 1px solid $color-light-gray2;
                    margin-bottom: 15px;
    
                    .queue_item {
                        width: 25%;
                        margin-bottom: 15px;
                        padding-left: 15px;
    
                        //primer p
                        :first-child {
                            margin: 0px;
                            color: $color-dark-blue;
                            font-weight: $semibold;
                            font-size: 11px;
                        }
    
                        //segundo p
                        :nth-child(2) {
                            margin: 0px;
                            color: $color-gray;
                        }
                    }
                }
            }
        }
    
        .detail_container {
            width: 100%;
            background-color: $color-white;
    
            @include media-query(desktop) {
                width: 30%;
            }
    
            .title_container {
                padding-top: 15px;
                padding-left: 10px;
                border-bottom: 1px solid $color-light-gray2;
    
                @include media-query(tablet) {
                    padding: 4px 30px;
                }
    
                h2 {
                    margin-bottom: 15px;
                    color: $color-dark-blue;
                    font-weight: $bold;
                    font-size: 18px;
                }

                .sheet_container{
                    display: flex;
                    flex-direction: column;
                    gap: 3px;

                    .sheet{

                        display: flex;
                        gap: 10px;

                        p{
                            font-size: 13px;
                            margin: 0px;

                            span{
                                font-weight: $semibold;
                                color: $color-dark-blue;
                        
                            }
                        }
    
                        svg {
                            width: 20px;
                            height: 20px;
                            color: $color-dark-blue;
                            cursor: pointer;
    
                            &:hover{
                                color: $color-cyan;
                            }
                        }
                    }

                    //ultimo div
                    div:last-child{
                        margin-bottom: 5px;
                    }
                }
            }
    
            .detail_body {
                display: flex;
                flex-wrap: wrap;
                padding: 10px;
                padding-top: 20px;
    
                @include media-query(tablet) {
                    padding: 30px;
                    padding-bottom: 15px;
                }
    
                .detail_item {
                    width: 50%;
                    margin-bottom: 15px;
    
                    //primer p
                    :first-child {
                        margin: 0px;
                        color: $color-dark-blue;
                        font-weight: $semibold;
                        font-size: 11px;
                        word-break: break-all;
                    }
    
                    //segundo p
                    :nth-child(2) {
                        margin: 0px;
                        color: $color-gray;
                    }
                }
            }
        }
    
        .on_air_container {
            width: 100%;
            overflow-x: scroll;
            margin-top: 20px;
            max-height: 600px;
            overflow-y: scroll;
    
            @include media-query(desktop) {
                width: 70%;
                padding-left: 15px;
                margin-top: 0px;
            }
    
            .on_air_table_container {
                background-color: $color-white;
                min-width: 950px;
                padding-top: 20px;

                .void_text{
                    color: $color-gray;
                    font-size: 14px;
                    font-weight: $semibold;
                    margin-left: 20px;
                    padding-bottom: 20px;
                }
    
                h2 {
                    margin: 0px;
                    color: $color-dark-blue;
                    margin-left: 20px;
                    margin-bottom: 20px;
                    font-size: 18px;
                    font-weight: $bold;
                }
    
                .table{

                    a {
                        &:hover{
                            text-decoration: underline;
                        }
                    }

                    thead{
                        th{
                            color: $color-dark-blue;
                            //alineacion de texto
                            text-align: center;
        
                            svg{
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
    
                    tbody{
                        tr{
                            td{
                                color: $color-gray;
                                //alineacion de texto
                                text-align: center;
    
                                svg{
                                    width: 20px;
                                    height: 20px;
                                    color: $color-dark-blue;
                                    //cursor: pointer;
    
                                    // &:hover{
                                    //     color: $color-dark-gray;
                                    // }
                                }
    
                                .status_circle{
                                    width: 8px;
                                    height: 8px;
                                    border-radius: 50%;
                                    display: inline-block;
                                }
    
                                .status_circle_green{
                                    background-color: $color-green;
                                }
    
                                .status_circle_red{
                                    background-color: $color-red;
                                }

                                .status_circle_gray{
                                    background-color: $color-light-gray3;
                                }
    
                                .status_circle_yellow{
                                    background-color: $color-gold;
                                }
                            }
                        }
                    }
                    
                }
            }
        }
    }
}


