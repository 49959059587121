// Common styles for the admin nav
%common-styles-nav {
    #nav-sidebar {
        background-color: $color-dark-blue2;
        width: 250px;
        min-width: 250px !important;

        .profile_items_container {
            display: flex;
            flex-direction: column;

            .profile_items {
                display: flex;
                align-items: center;
                margin-left: 15px;
                margin-top: 15px;
                margin-bottom: 5px;

                .img_profile {
                    width: 30px;
                    height: 30px;
                    border-radius: 4px;
                }

                .img_profile_box_info{
                    margin-left: 10px;

                    .img_chevron{
                        filter: invert(1);
                        cursor: pointer;

                        &:hover{
                            filter: invert(0.5);
                        }
                    }

                    .rotate_chevron {
                        transform: rotate(180deg);
                      }
                }
            }

            .profile_links {
                margin-left: 15px;
                display: flex;
                flex-direction: column;
                width: 200px;
                height: 100%;
                background-color: white;
                border-radius: 3px;

                a {
                    color: $color-dark-blue2;
                    font-weight: $light;
                    text-decoration: none;
                    font-weight: $medium;
                    margin-left: 5px;

                    &:hover {
                        color: $color-dark-blue2;
                        text-decoration: underline;
                    }
                }

                form {
                    margin-left: 5px;

                    button {
                        background-color: transparent;
                        color: $color-dark-blue2;
                        font-weight: $medium;
                        text-transform: capitalize;
                        font-size: 0.875rem;

                        &:hover {
                            color: $color-dark-blue2;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        #nav-filter {
            margin-top: 20px;
            margin-bottom: 10px;
            width: 220px;
            margin-left: 16px;
            height: 32px;
        }

        .module {
            margin-bottom: 15px;
            padding-bottom: 10px;
            border-bottom: 1px solid $color-white;
            background-color: $color-dark-blue2;

            table {
                display: flex;
                flex-direction: column;

                tr {
                    background-color: $color-dark-blue2;
                    th {
                        a {
                            color: $color-white;
                            font-size: 0.9rem;
                            font-weight: $light;

                            &:hover {
                                color: $color-white;
                                text-decoration: underline;
                            }
                        }
                    }

                    td {
                        a {
                            color: $color-white;
                            font-size: 0.9rem;
                            font-weight: $light;

                            &:hover {
                                color: $color-white;
                                text-decoration: underline;
                            }
                        }
                    }
                }
                .section {
                    color: $color-white;
                    font-size: 1rem;
                }
            }
        }
    }
}

.change-form {
    @extend %common-styles-nav; 
}

.change-list {
    @extend %common-styles-nav; 

    #content-start {
        #content {
            #changelist-search {
                div {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    #searchbar {
                        height: auto;
                    }
                }
            }
        }
    }
}

.password {
    @extend %common-styles-nav; 
}

