.detail_crud_container {
    background-color: $color-white;
    border-radius: 5px;
    padding: 25px;
    margin-bottom: 20px;
    box-shadow: 0px 3px 6px #00000029;
    position: relative;
    z-index: 1;
    p {
        font-size: 16px;
        color: #4A4A4A;
        margin-bottom: 10px;
        font-weight: $bold;

        span {
            font-weight: $regular;
        }
    }

    .back_arrow_link{
        color: $color-dark-blue2;
        display: block;
        margin-bottom: 20px;
        svg{
            cursor: pointer;
        }

        &:hover{
            color: $color-bright-blue;
        }
    }
}