.dots-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 20px;
    cursor: pointer;


    .dot {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: $color-dark-blue;
        margin: 1px 0;
    }

}

.popover_container{
    position: absolute;
    left: -101px;
    top: 0px;
    min-width: 127px;
    min-height: 83px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    flex-direction: column;
    padding: 5px;
    padding-left: 10px;
    gap: 5px;
    z-index: 2;

    a {
        font-size: 16px;
        color: $color-gray;

        &:hover{
            color: $color-dark-blue;
        }
    }

    svg {
        cursor: pointer;
        margin-left: auto;
        color: $color-dark-blue;
        width: 15px;
        height: 15px;

        &:hover{
            color: $color-red;
        }
    }

    input{
        background-color: $color-white;
        color: $color-gray;
        padding: 0;
        font-size: 16px;
        
        &:hover{
            background-color: $color-white;
            color: $color-dark-blue;
        }
    }
}

