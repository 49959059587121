.aforo {
    .dashboard_container {
        display: flex;
        flex-direction: column;

        @include media-query(desktopL) {
            flex-direction: row;
        }

        .dashboard_container_filter {

            padding: 5px;

            @include media-query(tablet) {
                padding: 0px;
            }
            @include media-query(desktopL) {
                width: 35%;
                max-width: 280px;
                min-width: 280px;
                // min-width: 300px;
            }


            .filter_sup{
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;

                @include media-query(desktop) {
                    position: absolute;
                    top: 90px;
                    right: 40px;
                }

                .recordatorios, .gestionar_camp{
                    width: 48%;
                    height: 45px;
                    background-color: $color-white;
                    display: flex;
                    color: $color-dark-blue2;
                    font-weight: $semibold;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    cursor: pointer;
                    padding-left: 10px;
                    padding-right: 11px;

                    @include media-query(tablet) {
                        padding-left: 15px;
                    }

                    @include media-query(desktop) {
                        width: 200px;
                        padding-left: 15px;
                    }

                    p{
                        margin: 0px;
                        width: 78px;

                        // mediaquerie hasta 768px
                        @media (max-width: 450px) {
                            font-size: 10px;
                        }
                    }

                    &:hover{
                        background-color: $color-light-gray;
                        border: 1px solid $color-dark-blue2;
                    }
                } 

                .recordatorios{
                    @include media-query(desktop) {
                        margin-right: 10px;
                    }
                }
                .selection{
                    p {
                        width: 133px;
                    }
                }
            }

            .container_filter {
                background-color: $color-white;
                padding: 10px;
                height: 100%;

                #accordionParent {
                    .accordion-item {
                        border: 0;

                        .accordion-header_parent {
                            margin-top: 0px;

                            .accordion-button_parent {
                                padding-top: 10px;
                                padding-bottom: 10px;
                                padding-left: 10px;
                                padding-right: 10px;

                                // mediaquerie hasta 768px
                                @media (max-width: 767px) {
                                    height: 50px;
                                }

                                &:not(.collapsed) {
                                    /* Estilos para .accordion-button cuando no tiene la clase .collapsed */
                                    color: #212529;
                                    background-color: transparent;
                                }

                                .filter_title {
                                    display: flex;
                                    color: $color-dark-blue2;
                                    font-size: 16px;
                                    padding-top: 10px;
                                    padding-bottom: 10px;
                                    padding-right: 10px;
                                    padding-left: 10px;

                                    p {
                                        margin: 0px;
                                        margin-left: 10px;
                                        font-weight: $bold;
                                    }
                                }
                            }
                        }

                        .accordion-body_parent {
                            padding: 10px;

                            .accordion_child {
                                .accordion-item {
                                    border: 0;
                                    border-bottom: 1px solid $color-light-gray;

                                    .accordion-header {
                                        margin: 0px;

                                        .accordion-button {
                                            padding-top: 20px;
                                            padding-bottom: 10px;
                                            padding-left: 10px;
                                            padding-right: 10px;
                                            font-size: 14px;
                                            font-weight: $semibold;
                                            // .accordion-button:not(.collapsed)

                                            &:not(.collapsed) {
                                                /* Estilos para .accordion-button cuando no tiene la clase .collapsed */
                                                color: #212529;
                                                background-color: transparent;
                                            }
                                        }
                                    }

                                    .accordion-body {
                                        padding-left: 10px;
                                        padding-right: 10px;
                                        padding-bottom: 15px;
                                        padding-top: 15px;

                                        input,
                                        select {
                                            width: 100%;
                                            height: 35px;
                                            // Si tiene disabled, se cambia el color de fondo

                                            &:disabled {
                                                background-color: $color-light-gray;
                                            }
                                        }

                                        
                                    }
                                }
                            }
                        }
                    }
                }



                .button_outline, .button_filled {
                    
                    padding: 0px;
                    height: 35px !important;
                    margin-top: 20px;
                    width: 100% !important;
                }

                .button_filled {
                    margin-top: 10px;
                    //display: none !important;

                    @include media-query(desktopL) {
                        //display: none !important;
                    }
                }
            }
        }
 
        .dashboard_container_content {
            @include media-query(desktop) {
                max-width: 520px; // width cards + gap
                margin-inline: auto;
            }
            @include media-query(desktopL) {
                width: 75%;
                padding-left: 10px;
                max-width: 1060px; // padding + width cards + gap
                margin-inline: unset;
            }

            .text_results {
                color: $color-dark-blue2;
                margin-top: 10px;
                margin-bottom: 10px;
                font-size: 18px;
                margin-left: 10px;

                @include media-query(tablet) {
                    margin-left: 0;
                }

                @include media-query(desktopL) {
                    margin-top: 0px;
                }
            }

            .text_filters_applied{
                font-size: 14px;
                font-weight: 600;
                color: $color-gray;
                display: inline-block;
                margin: 0px;
                margin-bottom: 7px;
                margin-left: 10px;

                @include media-query(tablet) {
                    margin-left: 0;
                }

                @include media-query(desktopL) {
                    margin-top: 0px;
                }
            }


            .list_filter_containers{
                display: flex;
                gap: 10px;
                align-items: center;
                margin-bottom: 10px;
                margin-left: 10px;

                @include media-query(tablet) {
                    margin-left: 0;
                }

                @include media-query(desktopL) {
                    margin-top: 0px;
                }

                .clean_filter_mobile{
                    display: inline-block;
                    margin: 0px;
                    font-size: 13px;
                    //Color de link
                    color: #0076c2;
                    cursor: pointer;
                    text-decoration: underline;

                    &:hover {
                        color: $color-bright-blue;
                        text-decoration: underline;
                    }

                    /* @include media-query(desktopL) {
                        display: none;
                    } */
                }
            }

            .filters_container{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .filter{
                    /* min-width: 120px; */
                    height: 30px;
                    background-color: #d9daea;
                    margin: 2px;

                    font-size: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px;
                    color: $color-dark-blue2;
                    font-weight: $semibold;
                }

                .filter:last-child {
                    margin-right: auto; /* Esto centra el último elemento a la izquierda */
                }
            }


            .container_content {
            //  display: flex;
            //  align-items: center;
            //  flex-wrap: wrap;
            //  justify-content: center;
            //  padding-top: 20px; 
                display: flex;
                flex-direction: row; // Cambia a fila para que los elementos ocupen lado a lado
                flex-wrap: wrap; // Asegura que los elementos se envuelvan cuando no hay espacio suficiente
                align-items: stretch; // Asegura que los elementos se estiren verticalmente
                justify-content: center; 
                gap: 10px;
                                                     

                @include media-query(desktopL) {
                    
                    justify-content: flex-start;
                    padding-top: 0px;
                }

                .card_container {
                    background-color: $color-white;
                    width: 250px;
                    // height: 640px;
                    min-width: 250px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    // margin-right: 10px;
                    margin-bottom: 50px;
                    height: inherit; // Asegura que todos los elementos .card_container tengan la misma altura

                    &.ddoh{
                        height: 550px;
                    }

                    .image_background {
                        height: 182px;
                        width: 100%;
                        overflow: hidden;
                        position: relative;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                        .status_container{
                                min-width: 80px;
                                height: 20px;
                                background-color: $color-dark-blue;
                                position: absolute;
                                bottom: 10px;
                                left: 10px; 
                                display: flex;
                                align-items: center;

                                .circle_status{
                                    width: 10px;
                                    height: 10px;
                                    border-radius: 50%;
                                    margin-left: 5px;

                                    &.green{
                                        background-color: $color-green;
                                    }
    
                                    &.yellow{
                                        background-color: $color-gold;
                                    }
    
                                    &.red{
                                        background-color: $color-red;
                                    }

                                    &.gray{
                                        background-color: $color-gray;
                                    }
                                }



                                p {
                                    color: $color-white;
                                    margin: 0px;
                                    margin-left: 4px;
                                    margin-right: 10px;
                                    font-size: 11px;
                                }
                        }
                    }

                    .card_description {
                        .card_title {
                            font-size: 16px;
                            font-weight: 600;
                            color: $color-dark-blue2;
                            margin-top: 10px;
                            margin-bottom: 10px;
                            margin-left: 15px;
                            text-transform: capitalize;
                        }

                        .row {
                            // Establece el padding-left para todos los elementos .col-6
                            .col-6 {
                                padding-left: 30px; // Ajusta el valor de padding según tus necesidades
                            }

                            // Anula el padding-left solo en los elementos .col-6 pares
                            .col-6:nth-child(even) {
                                padding-left: 0;
                            }

                            div {
                                margin-bottom: 10px;
                            }
                        }

                        .item_desc,
                        .title_desc {
                            margin: 0px;
                        }

                        .title_desc {
                            font-size: 10px;
                            font-weight: 600;
                            color: $color-dark-blue2;
                        }

                        .item_desc {
                            font-size: 12px;
                            font-weight: 400;
                            color: $color-gray;
                        }
                    }

                    .card_button_container {
                        border-top: 1px solid $color-light-gray;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        background-color: $color-white;

                        .card_button {
                            border: none;
                            color: $color-bright-blue;
                            font-weight: 700;
                            background-color: transparent;
                            margin-top: 8px;
                            margin-bottom: 8px;
                            font-size: 14px;

                            &:hover {
                                color: $color-dark-blue2;
                            }

                            // ultimo elemento
                            &:last-child {
                                margin-bottom: 15px;
                            }
                        }

                        

                    }
                }
            }


        }
    }
}
