.user {
    .div-con-fondo {
        font-size: 16px;
        display: none;

        // height: 500px;


        @include media-query(tablet) {
            display: flex;
            width: 100%;
            min-height: 420px;
            border-radius: 5px;
            overflow: hidden;
            max-width: 1120px;
            position: relative;
        }

        @include media-query(desktop) {
            min-height: 350px;
        }

        .lado-izquierdo {
            width: 25%;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: $color-dark-blue2;
                opacity: 0.1;
            }

            img {
                position: absolute;
                z-index: 1;
                top: 50%;
                transform: translateY(-50%);
                left: 50%;
                width: 100%;
                height: auto;
                max-width: 400px;
                min-width: 200px;
                min-height: 200px;
                max-height: 330px;
                border-radius: 13px;
            }
        }

        .lado-derecho {
            width: 75%;
            background: $color-white;
            padding-left: 30%;
            padding-top: 70px;

            @include media-query(desktop) {
                padding-left: 18%;
                padding-top: 70px;
            }

            .role {
                background: #e5e6f6;
                width: 150px;
                padding: 5px 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 3px;
                p {
                    margin: 0;
                    font-size: 12px;
                    color: $color-dark-blue;
                    font-weight: $semibold;
                }
            }
        }

        .description_profile {
            p {
                margin: 0;
                font-size: 16px;
            }

            .username {
                font-size: 20px;
                color: $color-dark-blue;
                font-weight: $semibold;
                margin-bottom: 10px;
            }

            .title {
                font-size: 16px;
                color: $color-dark-blue;
                font-weight: $semibold;
            }

            .email_container,
            .phone_container,
            .firstName_container,
            .lastName_container {
                margin-bottom: 10px;
            }
        }

        form {
            display: flex;
            flex-direction: column;
            padding-top: 10px;

            input:nth-child(1) {
                order: 1;
            }

            div:nth-child(2) {
                order: 7;
                label {
                    color: $color-dark-blue;
                    font-weight: $semibold;
                }
            }

            div:nth-child(3) {
                order: 5;
                display: flex;
                flex-direction: column;
                label {
                    color: $color-dark-blue;
                    font-weight: $semibold;
                }
                input {
                    width: 75%;
                    height: 40px;
                    border-radius: 0;
                    padding: 0 10px;
                    margin-top: 5px;
                    &.error {
                        border: 1px solid $color-red;
                    }
                }
            }

            div:nth-child(4) {
                order: 2;
                display: none;
                flex-direction: column;
                label {
                    color: $color-dark-blue;
                    font-weight: $semibold;
                }
                input {
                    width: 75%;
                    height: 40px;
                    border-radius: 0;
                    padding: 0 10px;
                    margin-top: 5px;
                    &.error {
                        border: 1px solid $color-red;
                    }
                }
            }

            div:nth-child(5) {
                order: 3;
                display: flex;
                flex-direction: column;
                label {
                    color: $color-dark-blue;
                    font-weight: $semibold;
                }
                input {
                    width: 75%;
                    height: 40px;
                    border-radius: 0;
                    padding: 0 10px;
                    margin-top: 5px;
                    &.error {
                        border: 1px solid $color-red;
                    }
                }
            }

            button:nth-child(8) {
                order: 10;
                margin-bottom: 20px;
            }

            div:nth-child(6) {
                order: 4;
                display: flex;
                flex-direction: column;
                label {
                    color: $color-dark-blue;
                    font-weight: $semibold;
                }
                input {
                    width: 75%;
                    height: 40px;
                    border-radius: 0;
                    padding: 0 10px;
                    margin-top: 5px;
                    &.error {
                        border: 1px solid $color-red;
                    }
                }
            }

            div:nth-child(7) {
                order: 6;
                display: flex;
                flex-direction: column;
                label {
                    color: $color-dark-blue;
                    font-weight: $semibold;
                }
                input {
                    width: 75%;
                    height: 40px;
                    border-radius: 0;
                    padding: 0 10px;
                    margin-top: 5px;
                    &.error {
                        border: 1px solid $color-red;
                    }
                }
            }

        }

        .button_outline {
            /* position: absolute;
            bottom: 20px;
            right: 20px; */
            width: 200px !important;
            height: 40px !important;
            margin-left: auto;
            margin-right: 20px;
            margin-top: 20px;
            border: 1px solid #0933f1 !important;
        }
    }



    .div_con_fondo_mobile {
        display: block;
        width: 100%;
        max-width: 500px;
        background: $color-white;
        position: relative;
        margin-top: 30px;
        padding-top: 60px;
        padding-bottom: 30px;
        margin-left: auto;
        margin-right: auto;

        @include media-query(tablet) {
            display: none;
        }

        .div_interno_con_color {
            background: $color-dark-blue;
            opacity: 0.1;
            width: 100%;
            height: 200px;
            position: absolute;
            top: 0;
            z-index: 0;
        }



        img.img_profile {
            z-index: 0;
            /* 350px o 100% */
            width: 100%;
            max-width: 350px;
            position: relative;
            display: block;
            margin: 0 auto;
            border-radius: 13px;
        }


        .container_descr{
            width: 100%;
            max-width: 430px;
            position: relative;
            display: block;
            margin: 0 auto;
            padding: 10px;

            &.new {
                .role_mobile {
                    background: #e5e6f6;
                    width: 150px;
                    padding: 5px 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 3px;
                    margin-top: 20px;
                    p {
                        margin: 0;
                        font-size: 12px;
                        color: $color-dark-blue;
                        font-weight: $semibold;
                    }
                }

                .form_container_inputs{
                        display: flex;
                        flex-direction: column;

                        div:nth-child(1){
                            order: 6;
                            width: 100%;
                            overflow: hidden;
                        }

                        div:nth-child(2){
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            font-size: 14px;
                            color: $color-dark-blue;
                            font-weight: $semibold;
                            margin-bottom: 10px;
                            order: 4;
                        }

                        div:nth-child(3){
                            display: none;
                            flex-direction: column;
                            gap: 10px;
                            font-size: 14px;
                            color: $color-dark-blue;
                            font-weight: $semibold;
                            margin-bottom: 10px;
                            order: 3;
                        }

                        div:nth-child(4){
                            order: 1;
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            font-size: 14px;
                            color: $color-dark-blue;
                            font-weight: $semibold;
                            margin-bottom: 10px;
                            margin-top: 25px;
                        }

                        div:nth-child(5){
                            order: 2;
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            font-size: 14px;
                            color: $color-dark-blue;
                            font-weight: $semibold;
                            margin-bottom: 10px;
                        }

                        div:nth-child(6){
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            font-size: 14px;
                            color: $color-dark-blue;
                            font-weight: $semibold;
                            margin-bottom: 10px;
                            order: 5;
                        }

                        input[type="text"] , input[type="email"], input[type="number"]{
                            width: 100%;
                            height: 40px;
                            border-radius: 0;
        
                            // clase para el error
                            &.error {
                                border: 1px solid $color-red;
                            }
                        }
                }

                
            }

            .info_container{
                margin-top: 20px;
                margin-bottom: 20px;
                width: 100%;
                max-width: 350px;
                position: relative;
                display: block;
                //margin: 0 auto;
    
                .username {
                    font-size: 20px;
                    color: $color-dark-blue;
                    font-weight: $semibold;
                    margin-bottom: 15px;
                    margin-top: 15px;
                }
    
                .title {
                    font-size: 14px;
                    color: $color-dark-blue;
                    font-weight: $semibold;
                }
    
                .email_container,
                .phone_container,
                .firstName_container,
                .lastName_container,
                .email_container
                {
                    margin-bottom: 10px;
                }

                
            }

            .modify_btn_mobile{
                width: 100% !important;
                height: 40px !important;
                margin-top: 20px;
            }
        }
    }

    .alert_container, .alert_container_mobile{
        width: 100%;
        height: 40px;
        margin-top: 5px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        border: 1px solid $color-red;
        width: 75%;

        p {
            margin: 0;
            font-size: 12px;
            color: $color-red;
            font-weight: $semibold;
            margin-left: 10px;
            margin-bottom: 0;
        }
    }

    .alert_container_mobile{
        margin-top: 20px;
        width: 100%;
    }
}
