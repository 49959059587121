// Bootstrap variables

// override default bootstrap colors for accessibility

// Whites
$color-white: #ffffff;
$color-light-gray: #f2f2f2;
$color-light-gray2: #dbdbdb;
$color-light-gray3:#535353;
$color-pale-pink: #f2dede;

// Blues
$color-dark-blue: #000eaa;
$color-dark-blue2: #000e8e;
$color-bright-blue: #0933f1;
$color-deep-blue: #0016a3;
$color-deeper-blue: #0116a2;
$color-light-blue: #f3fdfe;

// Grays
$color-gray: #707070;
$color-dark-gray: #4f4e4e;
$color-black-gray: #222222;

// Others
$color-gold: #fccd4f;
$color-green: #54c954;
$color-lime: #c4d600;
$color-cyan: #0ed1f3;
$color-red: #ff3838;
$color-dark-red: #a64242;


// ==================== BREAKPOINTS ====================
$mobilex: 375px !default;
$mobilexl: 480px !default; 
$mobile: 576px !default;
$tablet: 768px !default;
$desktop: 992px !default;
$desktopL: 1200px !default;
$desktopLS: 1440px !default;
$desktopXL: 1600px !default;


// ==================== FONT WEIGHTS ====================
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
