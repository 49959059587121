.blur_menu {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    right: 0;
    top: 70px;
    position: absolute;
    right: 0;
    z-index: 9;
    // blur
    backdrop-filter: blur(5px);
}

.user {
    .menu {

        position: relative;
        width: 250px !important;
        min-width: 250px !important;

        .submenu{
            width: 100%;
            min-height: 100vh;
            background-color: white;
            position: absolute;
            right: 0;
            border-left: 1px solid $color-dark-blue2;
            color: $color-dark-blue2;
            z-index: 1;
            

            @include media-query(tablet) {
                min-height: 100vh;
                border-right: 1px solid $color-dark-blue2;
                height: 100%;
            }

            .back_container{
                padding-top: 10px;
                padding-left: 10px;
                padding-bottom: 5px;
                border-bottom: 1px solid #00000030;
                display: flex;
                justify-content: space-between;

                @include media-query(tablet) {
                    flex-direction: row-reverse;
                    margin-top: 2px;
                    margin-left: -24px;
                    margin-bottom: 20px;
                }
                
                svg{
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                    //margin-left: 11px

                    @include media-query(tablet) {
                        margin-left: 11px;
                        margin-top: 5px;
                        margin-right: 20px;
                    }
                }


                p {
                    margin-left: 45px;
                    margin-top: 5px;
                    margin-bottom: 10px;
                    margin-right: 70px;
                    font-weight: $bold;
                    font-size: 18px;

                    @include media-query(tablet) {
                        margin-right: 20px;
                    }
                }
            }

            .container_sumbmenu_list{

                    // primer div de container_sumbmenu_list
                    div:first-child{
                        margin-top: 10px;
                    }



                    .menu_item_container{
                        margin-left: 30px;
                        align-items: center;
                        color: $color-dark-blue2;
                        margin-bottom: 10px;

                        a{
                            margin-left: 7px;
                            margin-top: 2px;
                        }

                        .menu_icon_container{
                            color: $color-dark-blue2;
                            position: relative;

                            .menu_icon{
                                color: $color-dark-blue2;
                            }

                            .circle_notification{
                                width: 10px;
                                height: 10px;
                                border-radius: 50%;
                                position: absolute;
                                bottom: 0px;
                                right: 0px;

                                &.color_notification{
                                    background-color: $color-cyan;
                                }

                                &.color_notification2{
                                    background-color: $color-lime;
                                }
                            }
                        }
                    }

            }
        }

        font-size: 16px;
        height: 100%;

        background-color: $color-dark-blue2;
        color: $color-white;
        display: block;

        @media (max-width: 767.9px) {
            //width: 270px;
            /* height: 100%; */
            position: absolute !important;
            right: -370px;
            top: 70px;
            width: 300px;
            z-index: 10;
            transition: right 1s ease;
            padding-left: 0;
        }

        .menu_sup {
            .profile_items_container {
                display: flex;
                flex-direction: column;
                margin-bottom: 25px;

                .profile_items {
                    display: flex;
                    align-items: center;
                    margin-left: 25px;
                    margin-bottom: 5px;
                    margin-top: 25px;

                    .img_profile {
                        width: 30px;
                        height: 30px;
                        border-radius: 4px;
                    }

                    .img_profile_box_info {
                        margin-left: 10px;

                        .img_chevron {
                            filter: invert(1);
                            cursor: pointer;

                            &:hover {
                                filter: invert(0.5);
                            }
                        }

                        .rotate_chevron {
                            transform: rotate(180deg);
                        }
                    }
                }

                .profile_links {
                    margin-left: 25px;
                    display: flex;
                    flex-direction: column;
                    width: 80%;
                    height: 100%;
                    background-color: white;
                    border-radius: 3px;

                    a {
                        color: $color-dark-blue2;
                        font-weight: $light;
                        text-decoration: none;
                        font-weight: $medium;
                        margin-left: 5px;

                        &:hover {
                            color: $color-dark-blue2;
                            text-decoration: underline;
                        }
                    }

                    form {
                        margin-left: 5px;

                        button {
                            background-color: transparent;
                            color: $color-dark-blue2;
                            font-weight: $medium;
                            text-transform: capitalize;
                            font-size: 0.875rem;

                            &:hover {
                                color: $color-dark-blue2;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }

        .menu_sup,
        .menu_inf {
            border-bottom: 1px solid #ffffff;
            padding-bottom: 15px;

            .menu_item_container {
                margin-left: 25px;
                margin-bottom: 25px;
                display: flex;
                align-items: center;

                .menu_icon {
                    filter: invert(1);
                    width: 20px;
                    height: 20px;
                }

                .link_menu {
                    margin-bottom: 0;
                    margin-left: 10px;
                    color: $color-white;
                    cursor: pointer;

                    &:hover {
                        color: $color-white;
                        text-decoration: underline;
                    }
                }
                
            }
        }

        .menu_sup{
            .profile_items_container{
                .profile_items{
                    .img_profile_box_info{
                        .m-0{
                            max-width: 150px;
                            overflow: hidden;
                        }
                    }


                }
            }
        }

        .logout_container {

            .logout{
                margin-left: 25px;
                margin-top: 15px;
                margin-bottom: 25px;
                display: flex;
                align-items: center;
               /*  position: absolute;
                bottom: 20px; */
    
                .logout_icon {
                    filter: invert(1);
                    width: 20px;
                    height: 20px;
                }
    
                #logout-form {
                    margin-left: 10px;
                    color: $color-white;
    
                    button {
                        background-color: transparent;
                        border: none;
                        color: $color-white;
                        font-weight: $medium;
                        text-transform: capitalize;
                        font-size: 0.875rem;
    
                        &:hover {
                            color: $color-white;
                            text-decoration: underline;
                        }
                    }
                }
            }
            
            .notifications{

                @include media-query(tablet) {
                    display: none;
                }

                padding-left: 25px;
                margin-top: 17px;
                border-bottom: 1px solid white;
                padding-bottom: 18px;
                display: flex;
                align-items: center;

                .menu_icon_container{
                    color: $color-white;

                    svg{
                        width: 20px;
                        height: 20px;
                    }
                }

                a{
                    margin: 0px;
                    margin-left: 10px;
                    color: $color-white;
                    cursor: pointer;
                    font-size: 0.875rem;
                    font-weight: $medium;

                    &:hover {
                        color: $color-white;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .menu-animado {
        right: -25px; /* Posición final deseada */
        max-height: 100vh;
        overflow-y: scroll;

        @include media-query(tablet) {
            min-height: 800px;
        }
    }
}
