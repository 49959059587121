// Common styles for the admin content
%common-styles-content {
    #content-start {
        background-color: $color-light-gray;

        .breadcrumbs{
            background-color: $color-light-gray;
            padding-top: 30px;
            color: $color-dark-blue2;
            font-size: 16px;

            a {
                color: $color-dark-blue2;
                font-size: 16px;
            }
        }
    }
}


.change-form {
    @extend %common-styles-content; // Extend the common styles to the admin-content
}

.change-list {
    @extend %common-styles-content; // Extend the common styles to the admin-content
}

.password {
    @extend %common-styles-content; // Extend the common styles to the admin-content
}