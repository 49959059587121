// common styles for toggle-nav-sidebar
%common-styles-toggle-nav-sidebar {

    .toggle-nav-sidebar {

        display: none;

        background-color: $color-dark-blue2;

        &::before {
            color: $color-white;
        }
    }
}

.change-list{
    @extend %common-styles-toggle-nav-sidebar; 
}

.change-form {
    @extend %common-styles-toggle-nav-sidebar; 
}

.password {
    @extend %common-styles-toggle-nav-sidebar; 
}