.body_messages{
    height: 100vh;
    display: flex;
    flex-direction: column;

    .header{
        background-color: $color-dark-blue;

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        height: 100px;

        img{
            width: 70px;
            height: 70px;
        }
    }

    .main_container{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: auto;

        .container{
            max-width: 800px;
            background-color: $color-white;
            margin: 0 auto;
            padding: 20px;
    
            p{
                margin: 0px;
            }
    
            .msg_title{
                padding-top: 20px;
                padding-bottom: 20px;
            }
    
            .main_msg{
                padding-top: 20px;
            }
        }
    }

    .footer{
        background-color: $color-dark-blue;
        color: $color-white;
        height: 200px;
        margin-top: auto;
        padding: 10px 20px;
        
        @include media-query(mobilex) {
            
        }

        @include media-query(tablet) {
            padding: 10px 115px;
            height: 155px;
        }

        .contac_container{

            max-width: 800px;
            margin: 0 auto;

            .contact_text_container{
                padding-top: 20px;
                border-bottom: 2px solid $color-white;
    
                p{
                    margin-bottom: 0px;
                }
            }
    
            .info_container{
                padding-top: 20px;
                display: flex;
                flex-direction: column;
                gap: 10px;
    
                @include media-query(tablet) {
                    flex-direction: row;
                    justify-content: space-between;
                    gap: 0px;
                }
            }
    
            .info_phone, .info_mail{
                p {
                    margin-bottom: 0px;
                }
            }
        }

        
    }

    
}

.rights_container{
    background-color: $color-black-gray;
    color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    height: 40px;
    font-size: 11px;
    
    @include media-query(mobilex) {
        font-size: 14px;
    }

    a{
        color: $color-white;
        text-decoration: underline;

        &:hover{
            color: $color-white;
        }

        &:visited{
            color: $color-white;
        }
    }
}