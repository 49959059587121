.reset{
    #header {

            padding: 0;
            background-color: $color-white;
            display: flex;

            #branding{
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $color-dark-blue;
                width: 100%;

                a {
                    margin: 10px;
                }
            }

            #user-tools {
                display: none;
            }
        }

        form {
            height: 250px;

            fieldset{
                height: 100%;
                display: flex;
                flex-direction: column;
                gap: 10px;
                
            }
        }
}