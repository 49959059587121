.change-form{

    
    /* EL INPUT CON EL ID id_business_unit */
    #id_business_unit {
        width: 100px;
    }

    #businessunit_form{
        #id_business_unit {
            width: 100%;
        }
    }
}