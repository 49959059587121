.filter_container{
    background-color: $color-white;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    position: relative;
    

    .btn_search_filter, .btn_reset_filter{
        height: 40px !important;
        border-radius: 4px;
        padding: 0PX;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .btn_search_filter{
        margin-left: 80px;
    }

    .btn_reset_filter{
        display: inline-block;
        margin-left: 10px;
    }

    .btn_desktop_container{
        @include media-query(desktopLS) {
            display: none ;
        }

        .btn_desktop{
            display: none !important;
    
            @include media-query(desktop) {
                display: flex !important;
                width: 100% !important;
                margin-left: 0px;
            }
    
            @include media-query(desktopLS) {
                display: none !important;
            }
        }
    }

    

    .btn_mobile{
        display: flex !important;

        @include media-query(desktop) {
            display: none !important;
        }

        @include media-query(desktopLS) {
            display: flex !important;
            width: 100% !important;
            margin-left: 0px;
        }

    }

    div {
        display: flex;
        flex-direction: column;

        

        //Invertir la posicion de los elementos desde desktopLS
        @include media-query(desktopLS) {
            flex-direction: column-reverse;
            justify-content: flex-end;
        }


        /* seleccionar todos los label */
        width: 100%;
        
        @include media-query(desktop) {
            width: 50%;
            padding-right: 5px;
            padding-left: 5px;
        }
        
        @include media-query(desktopLS) {
            width: 150px;

            &.buttons_container{
                margin-left: auto;
                flex-direction: row;
                min-width: 30% !important;
                gap: 10px;
            }
        }

        label {
            display: none;
        }

        span{
            label {
                display: block;
                margin-bottom: 10px;
            }
        }

        input, select {
            width: 100%;
            margin-bottom: 10px;
            height: 40px;
            margin-right: 10px;
            border-radius: 0;
            color: #6c6c6c;
        }

        &.buttons_container{
            input {
                width: 100% !important;
                margin: 0px;
                margin-bottom: 10px;

                

            }

            a{
                width: 100% !important;
                margin: 0px;
            }
        }

    }

  
}



input.button_filter{
    border: 2px solid $color-bright-blue;
    /* color: $color-white;
    background-color: $color-bright-blue; */
    color: $color-bright-blue;
    background-color: $color-white;
    font-size: 16px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;


    @include media-query(desktopLS) {
        margin-left: auto;
        width: 150px;
    }

    &:hover {
        color: $color-bright-blue;
        background-color: $color-white;
        transition: all .5s ease-out;
    }
    svg{
        margin-right: 10px;
        width: 40px;
    }

    
}