.not_found {
    height: 100%;
    overflow-x: hidden;

    #header {
        justify-content: center;
        padding-top: 15px;
        padding-bottom: 15px;

        #branding {
            justify-content: center;
        }

        #user-tools {
            display: none;
        }
    }

    #content-start {
        background-color: #c2c9e0;

        nav {
            display: none;
        }
        #content {
            padding: 0;

            .not_found_container {
                position: relative;

                img {
                    margin-left: 0px;
                    margin-top: 50px;
                }

                .text_container {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    text-align: center;
                    margin-top: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    min-width: 260px;

                    @include media-query(mobile) {
                        margin-top: 0px;
                    }

                    @include media-query(desktopL) {
                        margin-top: -10px;
                    }

                    h2 {
                        font-size: 15px;
                        font-weight: $extrabold;
                        margin-bottom: 10px;
                        margin-top: -8px;

                        @include media-query(mobilexl) {
                            font-size: 19px;
                            margin-top: -30px;
                            /* margin-bottom: 10px; */
                        }
                        @include media-query(mobile) {
                            font-size: 22px;
                        }
                        @include media-query(tablet) {
                            font-size: 30px;
                            margin-bottom: 15px;
                        }
                        @include media-query(desktop) {
                            font-size: 40px;
                        }
                        @include media-query(desktopL) {
                            font-size: 50px;
                        }
                        @include media-query(desktopLS) {
                            font-size: 60px;
                        }
                        @include media-query(desktopXL) {
                            font-size: 70px;
                        }
                    }
                    p {
                        font-size: 10px;
                        font-weight: 400;
                        margin-bottom: 10px;

                        @include media-query(mobilexl) {
                            margin-bottom: 12px;
                            font-size: 14px;
                        }
                        @include media-query(mobile) {
                        }
                        @include media-query(tablet) {
                            font-size: 16px;
                            margin-bottom: 20px;
                        }
                        @include media-query(desktop) {
                            font-size: 18px;
                            margin-bottom: 30px;
                        }
                        @include media-query(desktopL) {
                            font-size: 20px;
                        }
                        @include media-query(desktopLS) {
                            font-size: 22px;
                        }
                        @include media-query(desktopXL) {
                            font-size: 30px;
                        }
                    }

                    .button_outline {
                        width: 100px !important;
                        height: 30px !important;

                        @include media-query(mobilexl) {
                            width: 150px !important;
                            height: 30px !important;
                        }

                        @include media-query(desktop) {
                            width: 200px !important;
                            height: 40px !important;
                        }
                    }
                }
            }
        }
    }
}

.not_found.user {
    .not_found_container {
        img {
            margin-left: 0px !important;
            margin-top: 0px !important;
            margin-top: 20px !important;
        }
        .text_container {
            margin-left: 0 !important;
            margin-top: 0 !important;
            h2 {
                @include media-query(tablet) {
                    margin-bottom: 0 !important;
                    font-size: 20px !important;
                }

                @include media-query(desktop) {
                    font-size: 30px !important;
                    margin-bottom: 10px !important;
                }
            }
        }
    }
}
