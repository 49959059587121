.dashboard {
    #content{
        width: 100%;
        padding-left: 40px !important;
        padding-right: 40px !important;

        .dahsboard_title{
            font-size: 24px;
            font-weight: 600;
            color: $color-dark-blue2;
            margin-bottom: 0;
            padding-bottom: 30px;
        }

        .dashboard_container_reports{

            .dashboard_report_item{
                background-color: $color-white;
                max-width: 1400px;


                .report_title{
                    text-align: center;
                    padding-top: 20px;
                }

                .report_item{
                    // Todos los hijos div
                    div {
                        @include media-query(desktopL) {
                            width: 150px;
                            flex-direction: column;
                        }

                        label {
                            display: block;
                            font-size: 12px;
                            color: #818181;
                        }
                    }

                    .btn_search_filter {
                        width: 45px !important;
                        margin-right: 5px;
                        margin-left: 5px;

                        @include media-query(desktopL) {
                            margin-left: auto;
                            margin-top: 18px;
                        }
                    }

                    .btn_download{
                        width: 45px !important;
                        margin-right: 5px;
                        margin-left: 5px;

                        @include media-query(desktopL) {
                            margin-top: 18px;
                        }
                    }
                }

                .table_container{
                    max-height: 300px;
                    overflow: scroll;

                    table{
                        //min-width: 400px;
                        // min-height: 200px;
        
                        thead {
        
                            tr{
                                th{
                                    font-size: 14px;
                                    font-weight: 600;
                                    color: $color-dark-blue2;
                                    padding-top: 10px;
                                    padding-bottom: 10px;
                                    text-transform: none;
                                    border-bottom: 1px solid $color-light-gray2;
                                    text-align: center;
                                }
                            }
                        }
        
                        tbody{
                            tr{
                                td{
                                    color: $color-gray;
                                    text-align: center;
                                }
                            }
                        }
                    }

                
                                    // Estilos para el scroll en navegadores webkit (Chrome y Safari)
                &::-webkit-scrollbar {
                    width: 3px; // Grosor del scrollbar vertical en Chrome y Safari
                }
        
                &::-webkit-scrollbar-thumb {
                    background: $color-dark-blue2; // Cambiar el color del scrollbar en Chrome y Safari
                }
        
                &::-webkit-scrollbar-track {
                    background: #f1f1f1; // Cambiar el color del fondo del scrollbar en Chrome y Safari
                }
        
                // Estilos para el scroll en Firefox
                scrollbar-width: thin; // Grosor del scrollbar vertical en Firefox
                scrollbar-color: $color-dark-blue2 #f1f1f1; // Color del scrollbar en Firefox


                }
            }
        }

        .dashboard_container_main{

            // padding: 0 20px;
             display: flex;
             flex-direction: column;
             align-items: center;
             gap: 20px;
             width: 100%;
             flex-wrap: wrap;

             @include media-query(desktop) {
                flex-direction: row;
                max-width: 850px;
                align-items: flex-start;
            }

            @include media-query(desktopL) {
                max-width: 1000px;
            }

            @include media-query(desktopLS) {
                max-width: 1200px;
            }

            @include media-query(desktopXL) {
                max-width: 1400px;
            }
     
             .item_contianer{
                 background-color: $color-white;
                 padding: 10px;
                 width: 100%;
                 height: 460px;
                 max-width: 355px;
                 max-height: 480px;
                 overflow: scroll;

                 @include media-query(desktop) {
                    width: 70%;
                }

                @include media-query(desktopL) {
                    max-width: 390px;
                }

                @include media-query(desktopLS) {
                    max-width: 450px;
                }

                @include media-query(desktopXL) {
                    max-width: 560px;
                }
     
                 // Estilos para el scroll en navegadores webkit (Chrome y Safari)
                 &::-webkit-scrollbar {
                     width: 4px; // Grosor del scrollbar vertical en Chrome y Safari
                     height: 4px; // Grosor del scrollbar horizontal en Chrome y Safari
                 }
         
                 &::-webkit-scrollbar-thumb {
                     background: $color-dark-blue2; // Cambiar el color del scrollbar en Chrome y Safari
                 }
         
                 &::-webkit-scrollbar-track {
                     background: #ffffff; // Cambiar el color del fondo del scrollbar en Chrome y Safari
                 }
         
                 // Estilos para el scroll en Firefox
                 scrollbar-width: thin; // Grosor del scrollbar vertical en Firefox
                 scrollbar-color: $color-dark-blue2 #f1f1f1; // Color del scrollbar en Firefox
     
     
                 p {
                     font-size: 18px;
                     font-weight: 600;
                     color: $color-dark-blue2;
                     margin-bottom: 0;
                     padding-bottom: 10px;
                 }

                 canvas {
                    margin: 0 auto;
                 }
     
                 table{
                     //min-width: 400px;
                     // min-height: 200px;
     
                     thead {
     
                         tr{
                             th{
                                 font-size: 14px;
                                 font-weight: 600;
                                 color: $color-dark-blue2;
                                 padding-top: 10px;
                                 padding-bottom: 10px;
                                 text-transform: none;
                                 border-bottom: 1px solid $color-light-gray2;
                             }
                         }
                     }
     
                     tbody{
                         tr{
                             td{
                                 color: $color-gray;
                             }
                         }
                     }
                 }
             }


             
         }
    }

    
}