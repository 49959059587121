.user {
    #content-main {
        form.change_password {
            padding: 5px;

            fieldset{
                padding: 5px;
            }
        }
    }
    
}