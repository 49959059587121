.user {
    #content {
        background-color: $color-light-gray;
        padding-left: 10px;
        padding-right: 10px;

        .button_download {
            margin-left: 10px;
            margin-right: 10px;

            @include media-query(desktop) {
                margin-left: 0px;
                margin-right: 0px;
                position: absolute;
                right: 30px;
                top: 100px;
            }

            .recordatorios {
                display: flex;
                gap: 10px;
                align-items: center;

                height: 45px;
                padding-left: 10px;
                padding-right: 10px;
                background-color: rgb(255, 255, 255);
                color: rgb(0, 14, 142);
                font-weight: 600;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &:hover {
                    background-color: rgb(231, 231, 231);
                    border: 1px solid rgb(0, 14, 142);
                }

                p {
                    margin: 0;
                }
            }
        }

        .reserva_container {
            background-color: $color-light-gray;
            max-width: 1300px;

            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            &.step2 {
                .main_container {
                    width: 100%;
                    max-width: 1300px;

                    .title {
                        padding-left: 30px;
                        padding-top: 75px;

                        @media (min-width: 525px) {
                            padding-top: 30px;
                        }
                        @media (min-width: 768px) {
                            padding-top: 75px;
                        }
                        @media (min-width: 846px) {
                            padding-top: 30px;
                        }
                    }

                    .subtitle,
                    .subtitle_espacios {
                        color: $color-dark-gray;
                        padding-top: 0px;
                        font-size: 17px;
                    }
                    .subtitle_espacios {
                        margin-bottom: 10px;
                        margin-top: 30px;
                    }

                    .form_box {
                        padding: 0px;
                        // padding-bottom: 110px;
                        padding-bottom: 145px;
                        position: relative;

                        .form_container {
                            padding: 0px;

                            @keyframes animacion {
                                0% {
                                    transform: scale(
                                        1
                                    ); /* Tamaño normal al inicio de la animación */
                                }
                                50% {
                                    transform: scale(
                                        1.01
                                    ); /* Agrandar al 50% de la animación */
                                }
                                100% {
                                    transform: scale(
                                        1
                                    ); /* Volver al tamaño normal al final de la animación */
                                }
                            }

                            .alert-danger {
                                animation: animacion 2s infinite; /* 2 segundos de duración, repetición infinita */
                            }

                            .items_container {
                                display: grid;
                                grid-template-columns: repeat(
                                    auto-fill,
                                    minmax(190px, 1fr)
                                );
                                grid-gap: 15px;
                                padding-left: 30px;
                                justify-content: start; /* Alineación inicial a la izquierda */
                                margin-bottom: 20px;

                                .item {
                                    p {
                                        margin: 0;
                                    }

                                    p:nth-child(1) {
                                        font-size: 10px;
                                        font-weight: $bold;
                                        color: $color-dark-blue2;
                                    }

                                    p:nth-child(2) {
                                        font-size: 13px;
                                        font-weight: $regular;
                                        color: $color-gray;
                                    }
                                }
                            }

                            #modify_booking_form {
                                .items_container {
                                    display: grid;
                                    grid-template-columns: repeat(
                                        auto-fill,
                                        minmax(190px, 1fr)
                                    );
                                    grid-gap: 15px;
                                    padding-left: 30px;
                                    justify-content: start; /* Alineación inicial a la izquierda */
                                    margin-bottom: 20px;

                                    .item {
                                        margin: 0px;

                                        p {
                                            margin: 0;
                                        }

                                        input {
                                            height: 30px;
                                            width: 160px;
                                        }

                                        p:nth-child(1) {
                                            font-size: 10px;
                                            font-weight: $bold;
                                            color: $color-dark-blue2;
                                        }

                                        p:nth-child(2) {
                                            font-size: 13px;
                                            font-weight: $regular;
                                            color: $color-gray;
                                        }
                                    }
                                }
                                #modify_booking_btn,
                                #save_modify_booking_btn {
                                    position: relative;
                                    border-radius: 4px;
                                    top: 0px;
                                    right: 0px;
                                    width: 230px;
                                    margin-left: 30px;
                                    margin-top: 0px;
                                    margin-bottom: 25px;
                                    padding: 0px;
                                    height: 35px !important;
                                }
                            }

                            .design_container {
                                display: flex;
                                flex-direction: column;
                                padding-left: 30px;
                                padding-right: 30px;
                                gap: 15px;

                                .item {
                                    p {
                                        margin: 0;
                                    }
                                    a {
                                        font-size: 13px;
                                        font-weight: $regular;
                                        color: $color-gray;
                                        text-decoration: underline;

                                        &:hover {
                                            color: $color-dark-blue2;
                                        }
                                    }

                                    p:nth-child(1) {
                                        font-size: 10px;
                                        font-weight: $bold;
                                        color: $color-dark-blue2;
                                    }

                                    p:nth-child(2) {
                                        font-size: 13px;
                                        font-weight: $regular;
                                        color: $color-gray;
                                    }
                                }
                            }

                            .items_aproved_container {
                                display: grid;
                                grid-template-columns: repeat(
                                    auto-fill,
                                    minmax(190px, 1fr)
                                );
                                grid-gap: 15px;
                                padding-left: 30px;
                                justify-content: start; /* Alineación inicial a la izquierda */
                                margin-top: 20px;

                                .item {
                                    align-items: center;
                                    display: flex;
                                    //gap: 120px;

                                    p {
                                        margin: 0;
                                    }

                                    p:nth-child(1) {
                                        font-size: 10px;
                                        font-weight: $bold;
                                        color: $color-dark-blue2;
                                        width: 30px;
                                        margin-right: 10px;
                                    }

                                    p:nth-child(2) {
                                        font-size: 13px;
                                        font-weight: $regular;
                                        color: $color-gray;

                                        input[type='text'],
                                        select {
                                            width: 160px;
                                            height: 35px;
                                        }
                                    }

                                    input[type='checkbox'] {
                                        width: 18px;
                                        height: 18px;
                                        margin-top: 10px;
                                    }
                                }

                                &.approve {
                                    .item {
                                        width: 230px;
                                        gap: 140px;
                                    }
                                }
                            }

                            #aproved_btn,
                            #aproved_art_btn {
                                width: 200px !important;
                                height: 35px !important;
                                border-radius: 4px;
                                margin-left: 30px;
                                margin-top: 10px;
                                padding: 0px;
                                position: relative;
                                top: 0px;
                                right: 0px;
                            }

                            .item_button {
                                margin-left: 30px;

                                input {
                                    margin-top: 10px;
                                    margin-bottom: 0px;
                                    padding: 0px;
                                    width: 190px;
                                }
                            }

                            form {
                                .items_container {
                                    /* display: flex;
                                flex-wrap: wrap;
                                margin-top: 0px; */

                                    display: grid;
                                    grid-template-columns: repeat(
                                        auto-fill,
                                        minmax(190px, 1fr)
                                    );
                                    grid-gap: 15px;
                                    padding-left: 30px;
                                    justify-content: start; /* Alineación inicial a la izquierda */
                                    margin-top: 0px;

                                    .item {
                                        // min-width: 190px;
                                        margin-bottom: 15px;

                                        p {
                                            margin: 0px;
                                        }

                                        p:nth-child(1) {
                                            font-size: 10px;
                                            font-weight: $bold;
                                            color: $color-dark-blue2;
                                        }

                                        p:nth-child(2) {
                                            font-size: 16px;
                                            font-weight: $regular;
                                            color: $color-gray;
                                        }
                                    }
                                }

                                .button_filled {
                                    padding-top: 0px;
                                    padding-bottom: 0px;
                                }
                            }

                            .accordion {
                                .accordion-item {
                                    .accordion-header {
                                        margin-top: 0px;

                                        .accordion-button {
                                            padding-left: 30px;
                                            color: $color-dark-blue2;
                                            font-size: 15px;
                                            font-weight: $medium;
                                        }
                                    }
                                    .accordion-body {
                                        padding-left: 30px;

                                        .subtitle_data {
                                            color: $color-dark-gray;
                                            font-size: 17px;
                                            margin-bottom: 15px;
                                            margin-top: 20px;
                                            font-weight: $medium;
                                        }

                                        .especifications_container,
                                        .production_container {
                                            display: grid;
                                            grid-template-columns: repeat(
                                                auto-fill,
                                                minmax(190px, 1fr)
                                            );
                                            grid-gap: 15px;
                                            justify-content: start; /* Alineación inicial a la izquierda */

                                            .especifications_item {
                                                p {
                                                    margin: 0px;
                                                }

                                                p:nth-child(1) {
                                                    font-size: 10px;
                                                    font-weight: $bold;
                                                    color: $color-dark-blue2;
                                                }

                                                p:nth-child(2) {
                                                    font-size: 13px;
                                                    font-weight: $regular;
                                                    color: $color-gray;
                                                }
                                            }
                                        }

                                        .buttons_container {
                                            display: flex;
                                            gap: 10px;

                                            .button_outline {
                                                height: 40px !important;
                                                width: 150px !important;
                                                position: inherit;
                                                margin-top: 25px;
                                                margin-bottom: 15px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .button_outline {
                            height: 40px !important;
                            width: 200px !important;
                            display: flex;
                            gap: 5px;
                            position: absolute;
                            top: 20px;
                            right: 30px;

                            p {
                                margin: 0px;
                            }

                            &.add_spaces {
                                margin-left: 30px;
                                border-radius: 4px;
                            }
                        }

                        .buttons_inf_container {
                            display: flex;
                            justify-content: end;
                            flex-wrap: wrap;
                            gap: 10px;
                            position: absolute;
                            bottom: 30px;
                            right: 30px;

                            .button_outline {
                                position: inherit;
                                padding: 0px;
                                width: 193px !important;
                            }
                            .button_filled {
                                border-radius: 4px;
                                height: 41px !important ;
                            }
                        }
                    }
                }
            }

            @include media-query(tablet) {
                //min-height: 670px;
            }

            @include media-query(desktopL) {
                flex-direction: row;
            }

            .steps_container {
                background-color: $color-light-gray;
                padding: 10px;
                min-height: 160px;
                position: relative;

                @include media-query(desktopL) {
                    min-width: 280px;
                }

                .steps_box {
                    width: 100%;
                    height: 100%;
                    min-height: 140px;
                    background-color: $color-white;
                    padding-top: 15px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding-right: 66px;

                    @include media-query(desktopL) {
                        flex-direction: column;
                        justify-content: flex-start;
                        padding-top: 60px;
                        padding-right: 0px;
                    }

                    .step_1_container,
                    .step_2_container,
                    .step_3_container {
                        display: flex;
                        flex-direction: column;
                        margin-left: 20px;
                        margin-bottom: 15px;
                        margin-top: 15px;
                        width: 100px;

                        @include media-query(desktopL) {
                            width: 100%;
                            flex-direction: row;
                            align-items: center;
                        }

                        .step_1_cicrle,
                        .step_2_cicrle,
                        .step_3_cicrle {
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            background-color: $color-bright-blue;
                            color: $color-white;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 20px;
                            font-weight: bold;
                            margin-right: 10px;
                        }

                        .step_1_cicrle {
                            &.step_2 {
                                background-color: $color-dark-blue2;
                                color: $color-white;
                            }
                        }

                        .step_2_cicrle {
                            background-color: $color-dark-blue2;
                            color: $color-light-gray;

                            &.step_2 {
                                background-color: $color-bright-blue;
                                color: $color-white;
                            }
                        }

                        .step_3_cicrle {
                            background-color: $color-white;
                            border: 2px solid $color-dark-blue2;
                            color: $color-dark-blue2;

                            &.step_2 {
                                background-color: $color-dark-blue2;
                                color: $color-white;
                            }
                        }

                        .container_text_step_1,
                        .container_text_step_2,
                        .container_text_step_3 {
                            p {
                                margin: 0;
                            }

                            display: none;

                            @include media-query(desktopL) {
                                display: flex;
                                justify-content: center;
                                flex-direction: column;
                            }
                        }

                        .container_text_step_1 {
                            position: absolute;
                            bottom: 20px;
                            left: 35px;
                            display: block;

                            @include media-query(desktopL) {
                                position: relative;
                                bottom: 0;
                                left: 0;
                            }

                            :nth-child(1) {
                                font-size: 12px;
                                font-weight: bold;
                                color: $color-bright-blue;

                                @include media-query(desktopL) {
                                    font-size: 10px;
                                }
                            }

                            :nth-child(2) {
                                font-size: 18px;
                                font-weight: bold;
                                color: $color-bright-blue;

                                @include media-query(desktopL) {
                                    font-size: 14px;
                                }
                            }

                            &.step_2 {
                                display: none;
                                @include media-query(desktopL) {
                                    display: block;
                                }

                                :nth-child(1),
                                :nth-child(2) {
                                    color: $color-dark-gray;
                                }
                            }
                        }

                        .container_text_step_2 {
                            :nth-child(1) {
                                font-size: 10px;
                                font-weight: bold;
                                color: $color-dark-gray;
                            }
                            :nth-child(2) {
                                font-size: 14px;
                                font-weight: bold;
                                color: $color-dark-gray;
                            }

                            &.step_2 {
                                display: block;
                                min-width: 200px;
                                margin-top: 10px;

                                @include media-query(desktopL) {
                                    margin-top: 0px;
                                }

                                :nth-child(1) {
                                    font-size: 12px;
                                    font-weight: bold;
                                    color: $color-bright-blue;

                                    @include media-query(desktopL) {
                                        font-size: 10px;
                                    }
                                }

                                :nth-child(2) {
                                    font-size: 18px;
                                    font-weight: bold;
                                    color: $color-bright-blue;

                                    @include media-query(desktopL) {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }

                        .container_text_step_3 {
                            :nth-child(1) {
                                font-size: 10px;
                                font-weight: bold;
                                color: $color-dark-gray;
                            }
                            :nth-child(2) {
                                font-size: 14px;
                                font-weight: bold;
                                color: $color-dark-gray;
                            }
                        }
                    }
                }

                .separator {
                    display: none;
                    width: 3px;
                    height: 50px;
                    background-color: $color-dark-blue2;
                    margin-left: 0px;
                    margin-top: 15px;
                    margin-right: 20px;
                    // rotate: 90deg;
                    rotate: 90deg;

                    @include media-query(mobilex) {
                        display: block;
                    }

                    @include media-query(mobile) {
                        margin-top: -10px;
                        height: 103px;
                    }

                    @include media-query(tablet) {
                        margin-top: 15px;
                        height: 51px;
                    }

                    @media (min-width: 768px) {
                        display: none;
                    }
                    @media (min-width: 778px) {
                        display: block;
                    }

                    @include media-query(desktop) {
                        height: 103px;
                        margin-top: -8px;
                    }

                    @include media-query(desktopL) {
                        rotate: 0deg;
                        margin-top: 0;
                        margin-left: 45px;
                        margin-right: 0px;
                    }
                }
            }
            .main_container {
                background-color: $color-light-gray;
                padding: 10px;

                .progress_bar_container {
                    width: 100%;
                    height: 70px;
                    display: flex;
                    justify-content: center;
                    background-color: $color-white;
                    align-items: center;
                    border-bottom: 1px solid $color-light-gray2;
                    position: relative;

                    .progress_number {
                        position: absolute;
                        right: 30px;
                        top: 40px;
                        color: #707070;
                        font-size: 12px;
                        font-weight: $semibold;

                        p {
                            margin: 0px;
                        }
                    }

                    .progressbar {
                        width: 100%;
                        height: 4px;
                        margin: 0 30px;

                        // Estilo para la barra de progreso con el 33% de un color y el resto de otro
                        &.half_red_gray {
                            width: 100%;
                            height: 4px;
                            background-color: $color-light-gray2; // Color de fondo para el 66%
                            position: relative;

                            &::before {
                                content: '';
                                display: block;
                                // width: 33%; // El 33% inicial será de color rojo
                                width: var(
                                    --progress-width,
                                    0
                                ); // Utiliza la variable CSS para el ancho
                                height: 100%;
                                background-color: $color-red;
                            }
                        }

                        // Estilo para la barra de progreso con el 66% de un color y el resto de otro
                        &.half_yellow_gray {
                            width: 100%;
                            height: 4px;
                            background-color: $color-light-gray2; // Color de fondo para el 66%
                            position: relative;

                            &::before {
                                content: '';
                                display: block;
                                //width: 66%; // El 66% inicial será de color amarillo
                                width: var(
                                    --progress-width,
                                    0
                                ); // Utiliza la variable CSS para el ancho
                                height: 100%;
                                background-color: $color-gold;
                            }
                        }
                    }
                }

                .form_box {
                    width: 100%;
                    padding: 10px;
                    background-color: $color-white;
                    position: relative;

                    @include media-query(tablet) {
                        min-height: 600px;
                        padding-bottom: 90px;
                    }

                    .form_container {
                        padding: 15px;
                        padding-bottom: 80px;

                        @include media-query(tablet) {
                            padding: 15px;
                        }

                        form {
                            .button_filled {
                                position: absolute;
                                bottom: 30px;
                                right: 30px;
                            }
                        }

                        .accordion {
                            .accordion-item {
                                .accordion-header {
                                    margin-top: 0px;

                                    .accordion-button {
                                        padding-left: 30px;
                                        color: $color-dark-blue2;
                                        font-size: 15px;
                                        font-weight: $medium;
                                    }
                                }
                                .accordion-body {
                                    padding-left: 30px;

                                    .subtitle_data {
                                        color: $color-dark-gray;
                                        font-size: 17px;
                                        margin-bottom: 15px;
                                        margin-top: 20px;
                                        font-weight: $medium;
                                    }

                                    .especifications_container,
                                    .production_container {
                                        display: grid;
                                        grid-template-columns: repeat(
                                            auto-fill,
                                            minmax(190px, 1fr)
                                        );
                                        grid-gap: 15px;
                                        justify-content: start; /* Alineación inicial a la izquierda */

                                        .especifications_item {
                                            p {
                                                margin: 0px;
                                            }

                                            p:nth-child(1) {
                                                font-size: 10px;
                                                font-weight: $bold;
                                                color: $color-dark-blue2;
                                            }

                                            p:nth-child(2) {
                                                font-size: 13px;
                                                font-weight: $regular;
                                                color: $color-gray;
                                            }
                                        }
                                    }

                                    .buttons_container {
                                        display: flex;
                                        gap: 10px;

                                        .button_outline {
                                            height: 40px !important;
                                            width: 150px !important;
                                            position: inherit;
                                            margin-top: 25px;
                                            margin-bottom: 15px;
                                        }
                                    }
                                }
                            }

                            .delete_all {
                                height: 42px !important;
                                width: 195px !important;
                                margin-left: auto;
                                margin-right: 20px;
                                margin-top: 20px;

                                @include media-query(desktop) {
                                    position: absolute;
                                    right: 232px;
                                    bottom: 30px;
                                }
                            }
                        }

                        .content_bonus {
                            

                            fieldset {

                                // padding-left: 30px;
                                // margin-right: 30px;
                                // max-height: 106px;
                                // overflow-y: scroll;

                            // Ajustar el ancho del scrollbar, pero sin cambiar su apariencia por defecto
                            &::-webkit-scrollbar {
                                width: 8px; /* Ajusta el ancho del scroll */
                            }

                            &::-webkit-scrollbar-thumb {
                                background: #d4d4d4; /* Color del "pulgar" del scroll, puedes ajustarlo si lo necesitas */
                                // border-radius: 10px;  /* Hace que el pulgar sea redondeado */
                            }

                            &::-webkit-scrollbar-track {
                                background: #f0f0f0; /* Color de fondo del track */
                            }

                                .bonus_container {
                                    padding-left: 10px;
                                    display: flex;
                                    align-items: center;
                                    height: 30px;
                                    gap: 7px;

                                    input[type='checkbox'] {
                                        cursor: pointer;

                                        // Estilo normal para el checkbox habilitado
                                        width: 16px;
                                        height: 16px;
                                        background-color: white;
                                        border-radius: 3px;
                                        position: relative;

                                        &:checked {
                                            background-color: #0075ff;
                                        }

                                        // Estilos personalizados solo cuando el checkbox esté deshabilitado
                                        &:disabled {
                                            appearance: none; /* Elimina los estilos predeterminados del navegador */
                                            cursor: not-allowed; /* Opcional: cambiar el cursor para indicar que está deshabilitado */

                                            // Estilos para el ícono del checkmark solo cuando esté deshabilitado
                                            &::after {
                                                content: "";
                                                position: absolute;
                                                top: 0px;
                                                left: 5px;
                                                width: 6px;
                                                height: 10px;
                                                border: solid #ffffff;
                                                border-width: 0px 3px 3px 0;
                                                transform: rotate(45deg);
                                            }
                                        }

                                        // Estilos para el ícono del checkmark
                                        // &:checked::after {
                                        //     content: '';
                                        //     position: absolute;
                                        //     top: 1px;
                                        //     left: 5px;
                                        //     width: 5px;
                                        //     height: 8px;
                                        //     border: solid white;
                                        //     border-width: 0 2px 2px 0;
                                        //     transform: rotate(45deg);
                                        // }
                                    }

                                    &.active {
                                        label {
                                            font-weight: 700;
                                            color: #10169a;
                                        }
                                    }
                                }
                            }

                            .btn_send_bonus{
                                height: 35px !important;
                                width: 150px !important;
                                margin-left: 10px;
                                border-radius: 4px;
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.user.modal-open {
    .modal-dialog {
        &.moda_booking {
            width: 95% !important;
            max-width: 95% !important;

            .modal-body {
                .container_content {
                    display: flex;
                    flex-direction: row; // Cambia a fila para que los elementos ocupen lado a lado
                    align-items: stretch; // Asegura que los elementos se estiren verticalmente
                    justify-content: center; // Distribuye el espacio horizontal entre los elementos

                    .card_container {
                        margin-bottom: 10px; // Agrega espacio entre los elementos si lo deseas
                        height: inherit; // Asegura que todos los elementos .card_container tengan la misma altura
                        border: 1px solid #dfdfdf;
                    }

                    .card_button_container {
                        display: flex;
                        flex-direction: column;

                        a {
                            border-bottom: 1px solid #f2f2f2;
                            width: 100%;
                            text-align: center;
                            margin: 0px;
                            padding-top: 10px;
                            padding-bottom: 10px;

                            &:hover {
                                text-decoration: underline;
                                color: $color-dark-blue2;
                            }
                        }
                    }
                }
            }

            .modal-footer {
                .button_outline {
                    width: 195px !important;
                    height: 42px !important;
                    padding: 0px;
                }
            }
        }
    }
}
