.dashboard {
    #content {

        padding-left: 0;
        padding-right: 0;

        #content-main {

            .module {
                margin-bottom: 15px;
                table {
                    display: flex;
                    flex-direction: column;
                    border-radius: 3px;
                    overflow: hidden;
    
                    tbody {
                        tr {
                            background-color: $color-dark-blue2;
                            color: $color-white;
            
                            th {
                                a {
                                    color: $color-white;
                                    font-size: 0.9rem;
                                    font-weight: $light;
            
                                    &:hover {
                                        color: $color-white;
                                        text-decoration: underline;
                                    }
                                }
                            }
            
                            td {
                                a {
                                    color: $color-white;
                                    font-size: 0.9rem;
                                    font-weight: $light;
            
                                    &:hover {
                                        color: $color-white;
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
            
                    caption {
                        color: $color-white;
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }
}
