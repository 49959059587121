.login {

    /* overflow: hidden; */

    @include media-query(mobilexl) {
    }

    #header {
        #branding {
            display: flex;
            justify-content: center;
        }
    }

    .ubication {
        display: none;
    }

    .errornote{
        margin: 10px 0;
        padding-left: 40px;
    }

    #content-main {
        height: 100%;

        .container_background {
            height: 100%;
            background-image: url('./assets/images/bg_mobile.png');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            gap: 30px;

            @include media-query(tablet) {
                background-image: url('./assets/images/bg_desktop.png');
                gap: 10px;
            }

            @include media-query(mobile) {
                background-image: url('./assets/images/bg_desktop.png');
            }

            @include media-query(desktop) {
                gap: 100px;
            }

            .title_login {
                color: $color-white;

                &.subtitle {
                    font-weight: $regular;
                }

                @include media-query(desktopL) {
                    font-size: 80px;
                }
            }

            form {

                width: 330px;
                
                @include media-query(desktopL) {
                    min-height: 330px;
                }

                .title_login_form{
                    font-size: 20px;
                    margin-bottom: 0;
                    margin-top: 20px;
                }   

                .div_username{
                    margin-top: 18px;
                }

                // clase error
                .div_username, .div_password{
                    
                        position: relative;

                        input{
                            &.input_error{
                                border: 1px solid red;
                            }
                        }
                    
                }

                .div_password{
                    .eye{
                        position: absolute;
                        right: 8px;
                        background-color: white;
                        width: 29px;
                        display: flex;
                        justify-content: center;
                        top: 28px;
                        border-radius: 19px;
                        cursor: pointer;

                        @include media-query(tablet) {
                            top: 34px;
                        }
                    }
                }

                
                


                // input type submit
                input[type='submit'] {
                    height: 40px;
                }

                .forgot_pass{
                    margin-left: 40px;
                    margin-top: 10px;
                }

                .div_errornote{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    margin-top: 5px;
                    margin-bottom: 20px;
                    padding-left: 37px;
                    padding-right: 20px;
                }

                .errornote{
                    margin: 0;
                    border: 0;
                    padding: 0;
                    font-size: 10px;
                    color: $color-red;
                }
            }
        }
        #footer {
            margin-top: -27px;
            background-color: #000eaa3b;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;
            @include media-query(tablet) {
                margin-top: -38px;
            }

            p {
                margin: 0;
                color: $color-white;
                font-size: 12px;
                @include media-query(tablet) {
                    font-size: 20px;
                }
            }
        }
    }
}

.login.user{
    #branding {
        width: 100%;
    }
}