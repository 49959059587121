.user{
    // *seleccionar un nav con aria-label= Migas de pan
    nav[aria-label="Migas de pan"]{
        
        display: none;

        @include media-query(tablet) {
            display: block;
        }

    }

    
}