.button_outline{
    border: 2px solid $color-bright-blue !important;
    color: $color-bright-blue !important;
    background-color: $color-white !important;
    font-size: 16px !important;
    width: 140px !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    &:hover {
        color: $color-white !important;
        background-color: $color-bright-blue !important;
        transition: all .5s ease-out !important;
    }
    svg{
        margin-right: 3px !important;
        width: 20px !important;
    }
}


.button_filled,
a.button_filled
{
    border: 2px solid $color-bright-blue !important;
    color: $color-white !important;
    background-color: $color-bright-blue !important;
    font-size: 16px !important;
    width: 195px !important;
    height: 42px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    text-decoration: none;
    &:hover {
        color: $color-white !important;
        background-color: $color-dark-blue2 !important;
        border: 2px solid $color-dark-blue2 !important;
        transition: all .5s ease-out !important;
    }
    svg{
        margin-right: 10px !important;
        width: 20px !important;
    }

    /* visited */
    &:visited {
        color: $color-white !important;
        background-color: $color-bright-blue !important;

        /* hover */
        &:hover {
            color: $color-bright-blue !important;
            background-color: $color-white !important;
            transition: all .5s ease-out !important;
        }
    }
}
